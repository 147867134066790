<template>
  <div class="solution-partner">
    <Navgation bgColor="#060B4C" />
    <div class="solution-partner-container">
      <section class="banner">
        <div class="banner-content">
          <div class="banner-content-title">合伙人解决方案</div>
          <div class="banner-content-label">
            0成本轻交付的工商财税产品助您拓宽获客渠道，提高用户黏性
            助您打造数字化工商财税+传统企业服务全新生态
          </div>
        </div>
      </section>
      <section class="industry-problem">
        <div
          class="industry-problem-content"
          :class="{
            'pre-hide-300': true,
            'move-bottom-300': isShowIndustryProblem,
          }"
        >
          <div class="industry-problem-title section-title">行业痛点</div>
          <div class="industry-problem-modules">
            <div
              class="industry-problem-modules-item"
              v-for="(
                industryProblemItem, industryProblemIndex
              ) in industryProblemList"
              :key="industryProblemItem.title"
              v-show="activeIndustryProblemItem === industryProblemIndex"
            >
              <div class="industry-problem-modules-item-left">
                <img :src="industryProblemItem.img" alt="" />
              </div>
              <div class="industry-problem-modules-item-right">
                <div class="industry-problem-modules-item-right-title">
                  {{ industryProblemItem.title }}
                </div>
                <div class="industry-problem-modules-item-right-line"></div>
                <div class="industry-problem-modules-item-right-problems">
                  <div
                    class="industry-problem-modules-item-right-problems-item"
                    v-for="problemItem in industryProblemItem.problems"
                    :key="problemItem"
                  >
                    {{ problemItem }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 产品方案 -->
      <section style="background: #060b4c">
        <ProductProgram
          :class="{
            'pre-hide-300': true,
            'move-bottom-300-1': isShowProductProgram,
          }"
        />
      </section>
      <!-- 合作方式 -->
      <Cooperation type="partner" />
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import ProductProgram from '../components/productProgram.vue'
import Cooperation from '../components/cooperation.vue'

export default {
  name: 'solutionPartner',
  components: { Navgation, ProductProgram, Cooperation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      industryProblemList: [
        {
          title: '痛点分析',
          img: require('@/assets/images/solution/partner-industry-problem-icon.png'),
          problems: [
            '① 企业服务市场广阔，但竞争激烈公司发展陷入瓶颈期',
            '② 产品服务单一同质化严重，缺乏差异化竞争手段',
            '③ 获客难，粘客难，存量市场竞争激烈，客户容易被别人抢走',
            '④ 客户增值复购生态不完善',
          ],
        },
      ],
      activeIndustryProblemItem: 0,
      isShowIndustryProblem: false,
      isShowProductProgram: false,
    }
  },
  mounted() {
    this.isShowIndustryProblem = true
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 400) {
          this.isShowProductProgram = true
        }
      } else {
        if (scrollTop >= 650) {
          this.isShowProductProgram = true
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.solution-partner-container {
  margin-top: @navHeight;
  .section-title {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #102a55;
  }
  .banner {
    height: 440px;
    background: url('../../../assets/images/solution/partner-banner-bg.png')
      no-repeat;
    background-size: 100% 440px;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      &-title {
        margin-top: 144px;
        font-size: 54px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 75px;
        background: linear-gradient(
          270deg,
          #51ffc4 0%,
          #24eaff 40%,
          #1464ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &-label {
        margin-top: 12px;
        width: 552px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }
  .industry-problem {
    height: 650px;
    overflow: hidden;
    &-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .industry-problem-title {
        margin-top: 90px;
      }
      .industry-problem-modules {
        margin-top: 80px;
        &-item {
          width: 1024px;
          height: 304px;
          background: #ffffff;
          box-shadow: 0px 0px 40px 0px rgba(171, 190, 214, 0.38);
          display: flex;
          &-left {
            img {
              width: 378px;
              height: 304px;
            }
          }
          &-right {
            margin-left: 90px;
            &-title {
              margin-top: 46px;
              font-size: 32px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #060b4c;
            }
            &-line {
              margin-top: 18px;
              width: 32px;
              height: 4px;
              background: #2871ff;
              border-radius: 2px;
            }
            &-problems {
              margin-top: 28px;
              &-item {
                margin-top: 8px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(16, 42, 85, 0.65);
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
  .pre-hide-300 {
    transform: translateY(300px);
    opacity: 0;
  }
  .move-bottom-300 {
    animation: move-bottom-300 1s 0.2s forwards ease-in-out;
  }
  .move-bottom-300-1 {
    animation: move-bottom-300-1 1s 0.2s forwards ease-in-out;
  }
  .pre-hide-100 {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottom-100 {
    animation: move-bottom-100 1s 0.2s forwards ease-in-out;
  }
  @keyframes move-bottom-300 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-300-1 {
    0% {
      transform: translateY(300px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  @keyframes move-bottom-100 {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
}
</style>
