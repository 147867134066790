var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"solution-partner"},[_c('Navgation',{attrs:{"bgColor":"#060B4C"}}),_c('div',{staticClass:"solution-partner-container"},[_vm._m(0),_c('section',{staticClass:"industry-problem"},[_c('div',{staticClass:"industry-problem-content",class:{
          'pre-hide-300': true,
          'move-bottom-300': _vm.isShowIndustryProblem,
        }},[_c('div',{staticClass:"industry-problem-title section-title"},[_vm._v("行业痛点")]),_c('div',{staticClass:"industry-problem-modules"},_vm._l((_vm.industryProblemList),function(industryProblemItem,industryProblemIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeIndustryProblemItem === industryProblemIndex),expression:"activeIndustryProblemItem === industryProblemIndex"}],key:industryProblemItem.title,staticClass:"industry-problem-modules-item"},[_c('div',{staticClass:"industry-problem-modules-item-left"},[_c('img',{attrs:{"src":industryProblemItem.img,"alt":""}})]),_c('div',{staticClass:"industry-problem-modules-item-right"},[_c('div',{staticClass:"industry-problem-modules-item-right-title"},[_vm._v(" "+_vm._s(industryProblemItem.title)+" ")]),_c('div',{staticClass:"industry-problem-modules-item-right-line"}),_c('div',{staticClass:"industry-problem-modules-item-right-problems"},_vm._l((industryProblemItem.problems),function(problemItem){return _c('div',{key:problemItem,staticClass:"industry-problem-modules-item-right-problems-item"},[_vm._v(" "+_vm._s(problemItem)+" ")])}),0)])])}),0)])]),_c('section',{staticStyle:{"background":"#060b4c"}},[_c('ProductProgram',{class:{
          'pre-hide-300': true,
          'move-bottom-300-1': _vm.isShowProductProgram,
        }})],1),_c('Cooperation',{attrs:{"type":"partner"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner"},[_c('div',{staticClass:"banner-content"},[_c('div',{staticClass:"banner-content-title"},[_vm._v("合伙人解决方案")]),_c('div',{staticClass:"banner-content-label"},[_vm._v(" 0成本轻交付的工商财税产品助您拓宽获客渠道，提高用户黏性 助您打造数字化工商财税+传统企业服务全新生态 ")])])])
}]

export { render, staticRenderFns }